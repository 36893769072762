import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Home";
import Footer from "../../components/Footer";
import Account from "../Account";
import Settings from "../Settings";
import useWindowDimensions from "../../Hook/useWindowDimensions";
import SideMenu from "../../components/SideMenu";
import Profile from "../Profile";
import Services from "../Services";
import Calendar from "../Calendar";
import { useSelector } from "react-redux";
import ContactUs from "../ContactUs";
import TopMenu from "../../components/TopMenu";
// import { getCustomerData } from "../../store/features/auth/customerData";
// import { getContactSubs } from "../../store/features/deal/getContactSubs";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

function Main() {
  // @ts-ignore
  let hideMenuBar = useSelector((state) => state.hideMenuBar);
  const { width } = useWindowDimensions();
  // const hasMobile = width <= 768;
  // @ts-ignore
  // const dispatch = useDispatch<AppDispatch>();
  // @ts-ignore
  // const loggedInUser = useSelector((state) => state.customerData);
  // @ts-ignore
  const userSub = useSelector((state) => state.getContactSubs.subscription);
  // eslint-disable-next-line
  const [stripePromise, setStripePromise] = useState(() => {
         return loadStripe(
            userSub[0]?.isFranchised
            ? process.env.REACT_APP_STRIPE_FRANCHISED_PUBLIC_KEY
            : process.env.REACT_APP_STRIPE_CORPORATE_PUBLIC_KEY
        )
    }
  );

  
  useEffect(() => {
    if (width > 768) {
      document.body.classList.add("has-webview");
    } else {
      document.body.classList.remove("has-webview");
    }
  }, [width, hideMenuBar]);

  const pathname = window.location.pathname;

  return (
    <Elements stripe={stripePromise}>
      {/* Header */}
      <div
        className={"app-main-header"}
        style={{ height: 15 }}
      // style={{ height: 92, background: "#f5f6f6" }}
      />
      {/* <div className={"main-app-container-fixed-height"}> */}
      <div className={"container dashboard-container"}>
        <div>
          <TopMenu />
        </div>

        <div className={"app-main-body-container"}>
          <div className={"app-main-sidebar"}>
            {hideMenuBar.changePlan ||
              pathname === "/dashboard/services/change-plan" ||
              pathname === "/dashboard/services/change-plan/add-on" ||
              pathname === "/dashboard/services/change-plan/summary" ||
              pathname === "/dashboard/services/change-plan/success" ? (
              ""
            ) : (
              <SideMenu />
            )}
          </div>
          <div className={"gutter"} />
          <div style={{ flex: 1 }}>
            <Routes>
              <Route path="*" element={<Home />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/services" element={<Services />} />
              <Route path="/plan" element={<Services />} />
              <Route path="/account" element={<Account />} />
              <Route path="/billing" element={<Services />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/billing/add-card" element={<Services />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/contactus" element={<ContactUs />} />
            </Routes>
          </div>
        </div>
      </div>
      {/* </div> */}
      <div className={"app-main-footer"}>
        <Footer />
      </div>
    </Elements>
  );
}

export default Main;
