import "@stripe/stripe-js";
import React, { useCallback, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDetectAdBlock } from "adblock-detect-react";
import Main from "./pages/Main";
import Signup from "./pages/Signup";
import Login from "./pages/Login/Login";
import Amplify from "aws-amplify";
import config from "./aws-exports";
import { store } from "./store";
import { Navigate } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { setUser } from "./store/features/auth/authSlice";
import { AppDispatch } from "./store";
import { getCustomerData } from "./store/features/auth/customerData";
import { getContactSubs } from "./store/features/deal/getContactSubs";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-responsive-modal/styles.css";
import { Auth } from 'aws-amplify';
import { CirclesWithBar } from "react-loader-spinner";
import { ErrorBoundary } from "react-error-boundary";
import { getCustomerSubscriptions } from "./api";
import { createInstance, OptimizelyProvider } from "@optimizely/react-sdk";
import ErrorModal from "./components/ErrorModal";
import { sentryCaptureError } from "./components/utils";
// import Retool from 'react-retool';

const optimizely = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY_SDK_KEY
});

Amplify.configure(config);

function App() {
  const adBlockDetected = useDetectAdBlock();
  React.useEffect(() => {
    if (adBlockDetected) {
      sentryCaptureError("ad block detected", {});
    }
  }, [adBlockDetected]);
  return (
    <Provider store={store}>
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => (
          <>
            <ErrorModal
              isOpen={true}
              message={error.message}
              onClose={() => {
                window.location.reload();
              }}
              crash={true}
            />
          </>
        )}
        // fallbackComponent={<ErrorModal />}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        <Navigation />
      </ErrorBoundary>
    </Provider>
  );
}

export default App;

const Navigation = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useSelector((state) => state["auth"]);

  const hasAwsUser = useCallback(async () => {
    try {
      let customerSubscriptions = []
      const user = await Auth.currentAuthenticatedUser()
      if (user) {
        // @ts-ignore
        let hasUser = await dispatch(getCustomerData());
        // @ts-ignore
        let userDataId = hasUser?.payload?.user?.attributes[`custom:hubspotContactId`] || user?.attributes['custom:hubspotContactId']

        if (userDataId) {
          // @ts-ignore
          customerSubscriptions = await getCustomerSubscriptions(userDataId);
          if (customerSubscriptions.length > 0) {
            await dispatch(
              getContactSubs(
                // @ts-ignore
                customerSubscriptions
              )
            );
          }
        }
        // @ts-ignore
        dispatch(setUser(hasUser?.payload?.user?.attributes));
      } else {
        console.log('User not logged in')
      }

      setIsLoading(false);
    } catch (error) {
      dispatch(setUser(false));
      setIsLoading(false);
    }
  }, [dispatch]);

  React.useEffect(() => {
    hasAwsUser();
  }, [hasAwsUser]);

  if (isLoading) {
    return (
      <div className="everything-centered-of-page">
        <CirclesWithBar
          height="100"
          width="100"
          color="#3eb5ab"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          outerCircleColor="#fdc815"
          innerCircleColor="#3eb5ab"
          barColor="#3eb5ab"
          ariaLabel="circles-with-bar-loading"
        />
      </div>
    );
  }

  return (
    <OptimizelyProvider
      optimizely={optimizely}
      user={{
        id: user?.sub ? user.sub : ""
      }}
    >
      <BrowserRouter>
        <Routes>
          {/* <Route path="/settings" element={<CreditCard/>} /> */}
          <Route
            path="/dashboard/*"
            element={
              <ProtectMain>
                <MainRoutes />
              </ProtectMain>
            }
          />
          <Route
            path="*"
            element={
              <CheckAuth>
                <AuthRoutes />
              </CheckAuth>
            }
          />
        </Routes>
      </BrowserRouter>
    </OptimizelyProvider>
    // <Retool
    //   url="https://recyclops.retool.com/embedded/public/4812fdeb-43da-41aa-b254-91cf19d952e3"
    //   data={{name: 'Sample data'}}
    //   styling={{position: "absolute", height: "100% !important", width: "100% !important"}}
    // />
  );
};

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Main />} />
    </Routes>
  );
};

const AuthRoutes = () => (
  <Routes>
    <Route path="" element={<Login />} />
    <Route path="/login" element={<Login />} />
    <Route path="/signup" element={<Signup />} />
  </Routes>
);

const CheckAuth = ({ children }) => {
  const { user } = useSelector((state) => state["auth"]);
  if (user) {
    return <Navigate to={"/dashboard"} replace />;
  }
  return children;
};

const ProtectMain = ({ children }) => {
  const { user } = useSelector((state) => state["auth"]);

  if (!user) {
    return <Navigate to={"/login"} replace />;
  }
  return children;
};
