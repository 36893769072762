import { useRef } from "react";
import { Modal } from "react-responsive-modal";
import AppButton from "../AppButton";

function ErrorModal({ isOpen, onClose, message, crash = false }) {
  const ref = useRef(null);

  return (
    <Modal
      ref={ref}
      styles={{ modal: { borderRadius: 20 } }}
      center
      open={isOpen}
      onClose={onClose}
      onAnimationEnd={() => {
        if (!isOpen) {
          if (ref.current) {
            ref.current.style.opacity = "0%";
          }
          if (ref.current?.parentElement?.parentElement) {
            ref.current.parentElement.parentElement.style.opacity = "0%";
          }
        }
      }}
    >
      <div className={"error-modal-container"}>
        <img src={require("../../assets/images/logo.png")} alt="" />
        <div className={"error-modal-body"}>
          <div className={"error-modal-static"}>
            <span className={"error"}>Oh no!</span>
            <span className={"error"}>There was an error</span>
          </div>
          <p>{message}</p>
          {crash && (
            <span className={"error"}>
              If the issue persists please{" "}
              <a
                href="https://ask.recyclops.com/kb-tickets/new"
                target="_blank"
                style={{ fontSize: "16px" }}
                rel="noreferrer"
              >
                contact support.
              </a>
            </span>
          )}
        </div>
        {crash ? (
          <AppButton
            title={"Try Again"}
            onClick={() => {
              window.location.reload();
            }}
            className={"active"}
          />
        ) : (
          <AppButton
            title={"Try Again"}
            onClick={onClose}
            className={"active"}
          />
        )}
      </div>
    </Modal>
  );
}

export default ErrorModal;
